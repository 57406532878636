.topbar {
  @include position(absolute, 0 0 null 0);
  text-align: center;
  background-color: white;
  height: 60px; }

.logo {
  width: 80px;
  margin-top: 10px; }

.main-wrapper {
  @include wrap;
  padding-top: 120px;
  height: 100%;
  min-height: 100%;
  padding-bottom: 100px; }

@include after(tablet) {
  .logo {
    width: 120px;
    margin-top: 20px; }
  .topbar {
    text-align: left;
    height: 100px; }
  .main-wrapper {
    padding-top: 190px; } }
