@mixin wrap($size: 1000px, $width: 90%) {
  @include margin(null auto);
  width: $width;
  max-width: $size; }

@mixin after($bp) {
  @if not map-has-key($breakpoints, $bp) {
    @error "Error: '#{$bp}' is not a valid breakpoint name."; }
  @else {
    @media (min-width: map-get($breakpoints, $bp)) {
      @content; } } }
