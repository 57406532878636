// Layout

.wrapper {
  @include wrap; }

.s-right {
  margin-right: 1rem !important; }
.ds-right {
  margin-right: 2rem !important; }
.ts-right {
  margin-right: 3rem !important; }

.full-height {
  height: 100%; }

.vam {
  vertical-align: middle; }

.clearfix {
  @include clearfix; }

.no-transitions {
  @include transition(none !important); }

// Visibility

.show-on-phone {
  visibility: hidden; }

.phone {
  .show-on-phone {
    visibility: visible; } }

// Flexbox

.flex--center {
  @include display(flex);
  @include align-items(center); }

@for $index from 1 through 12 {
  .col-#{$index} {
    @include flex($index); } }
