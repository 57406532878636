*, *:before, *:after {
  box-sizing: inherit; }

html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 12px; }

body {
  margin: 0;
  background-color: $bg-color; }

html, body {
  height: 100%; }

.waveform-canvas {
  @include position(fixed, 0);
  width: 100%;
  height: 100%;
  z-index: -1; }

@include after(phone-land) {
  html {
    font-size: 14px; } }

@include after(tablet) {
  html {
    font-size: 16px; } }

@include after(laptop) {
  html {
    font-size: 18px; } }
