.pl-image-bg {
  width: 150px;
  max-width: 100%;
  border: 0.8rem solid white;
  background-color: white;
  box-shadow: 0 1px 1px 1px rgba(black, 0.15); }

%pl-title {
  @include ellipsis;
  letter-spacing: 0.02em;
  line-height: 1;
  margin: 0; }

.pl-title {
  @extend %pl-title;
  font-size: 4.2rem;
  color: $border-color; }

.pl-subtitle {
  @extend %pl-title;
  font-size: 2.6rem;
  color: $border-color-1; }

.pl-subtitle-cont {
  margin-bottom: 1rem; }

.service-badges {
  padding: 1rem 0;
  .service-badge {
    margin-right: 0.5rem;
    img {
      height: 26px; }
    &.hertz-not-set {
      opacity: 0.6;
      cursor: default; } }
  .service-badge:last-child {
    margin-right: 0; } }

.pl-share-buttons {
  @include transition(opacity 200ms);
  @include user-select(none);
  padding: 1rem 0;
  cursor: default;
  .pl-share-button {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    color: $border-color-1;
    &:hover {
      color: $border-color; }
    &:last-child {
      margin-right: 0; } }
  .leyend {
    color: $border-color-1;
    font-weight: bold;
    font-size: 0.9rem;
    margin-right: 0.5rem; }
  &.hertz-not-set {
    visibility: hidden;
    opacity: 0; } }

.player {
  max-width: 100%;
  width: 100%; }

.main-flex-wrapper {
  @include display(flex);
  @include align-items(center);
  @include flex-flow(column);
  @include justify-content(center);
  min-height: 100%; }

.player-cont {
  width: 100%;
  max-width: 100%;
  text-align: center; }

.player-cont-aside {
  @include flex(none);
  margin-bottom: 2rem; }

.player-image-cont {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem; }

.player-controls-cont {
  max-width: 100%;
  min-width: 0; } // fix ellipsis inside

.player-like {
  i {
    display: inline-block; }
  &.hertz-used i {
    @include animation(heartbeat 2.5s); } }

.player-progress {
  @include position(fixed, 0 null 0 0);
  @include transition(transform 1s linear);
  @include transform(scale(0,1));
  @include transform-origin(left center);
  background-color: shade($bg-color, 8%);
  width: 100%;
  z-index: -1; }

@include after(phone-land) {
  .pl-image-bg {
    width: 300px; } }

@include after(tablet) {
  .player-cont {
    text-align: left; }
  .player {
    @include display(flex);
    @include align-items(center);
    @include flex-direction(row); }
  .player-image-cont {
    margin-right: 3rem;
    margin-bottom: 0;
    width: auto; }
  .player-controls-cont {
    @include flex(1); } }

@include after(laptop) {
  .main-flex-wrapper {
    @include flex-direction(row); }
  .player-cont-aside {
    @include flex(none); }
  .player-cont {
    @include flex(1);
    margin-bottom: 0; } }

@include keyframes(heartbeat) {
  0% {
    @include transform(scale(1)); }
  5% {
    @include transform(scale(1.2)); }
  10% {
    @include transform(scale(1.1)); }
  15% {
    @include transform(scale(1.3)); }
  50% {
    @include transform(scale(1)); }
  100% {
    @include transform(scale(1)); } }








