%btn {
  @include appearance(none);
  border: none;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  text-align: center;
  box-shadow: 0 1px 1px 1px rgba(black, 0.15);
  -webkit-tap-highlight-color: rgba(0,0,0,0); }

.btn {
  @extend %btn;
  &--fab {
    @extend %btn;
    @include transition(transform 200ms, box-shadow 200ms);
    @include size(3.5rem);
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      @include transform(scale(1.1));
      box-shadow: 0 2px 2px 2px rgba(black, 0.15); }
    &:active {
      @include transform(scale(0.9));
      box-shadow: 0 0px 0px 0px rgba(black, 0.15); }
    &:focus {
      outline: none; }
    &:disabled {
      opacity: 0.6;
      cursor: default;
      @include transform(scale(1) !important); }
    i {
      font-size: 1.6rem;
      color: $accent-color; } } }

.pl-toggle i:before {
  content: "\f488";
  font-size: 1.6rem;
  padding-left: 5px; }

.hertz-playing .pl-toggle i:before {
  content: "\f478";
  font-size: inherit;
  padding-left: 0; }
